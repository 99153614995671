// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/v2/theming/
$font-path: "../assets/fonts";
//@import "ionic.globals";
@font-face {
  font-family: Point32HealthSansRegular;
  src: url($font-path + "/Point32HealthSans-Regular.ttf") format('ttf'), url($font-path + "/Point32HealthSans-Regular.otf") format('otf'), url($font-path + "/Point32HealthSans-Regular.woff") format('woff');
  font-weight: normal;
  font-style: normal;
  font-variant: normal;
}
@font-face {
  font-family: Point32HealthSansBold;
  src: url($font-path + "/Point32HealthSans-Bold.ttf") format('ttf'), url($font-path + "/Point32HealthSans-Bold.otf") format('otf'), url($font-path + "/Point32HealthSans-Bold.woff") format('woff');
  font-weight: normal;
  font-style: normal;
  font-variant: normal;
}
@font-face {
  font-family: Point32HealthSansMedium;
  src: url($font-path + "/Point32HealthSans-Medium.ttf") format('ttf'), url($font-path + "/Point32HealthSans-Medium.otf") format('otf'), url($font-path + "/Point32HealthSans-Medium.woff") format('woff');
  font-weight: regular;
  font-style: normal;
  font-variant: normal;
}
@font-face {
  font-family: Point32HealthSansLight;
  src: url($font-path + "/Point32HealthSans-Light.ttf") format('ttf'), url($font-path + "/Point32HealthSans-Light.otf") format('otf'),  url($font-path + "/Point32HealthSans-Light.woff") format('woff');
  font-weight: regular;
  font-style: normal;
  font-variant: normal;
}
@font-face {
  font-family: MontserratMedium;
  src: url($font-path + "/Montserrat-Medium.ttf") format('ttf'), url($font-path + "/Montserrat-Medium.otf") format('otf'), url($font-path + "/Montserrat-Medium.eot") format('eot'), url($font-path + "/Montserrat-Medium.svg") format('svg'), url($font-path + "/Montserrat-Medium.woff") format('woff');
  font-weight: Medium;
  font-style: normal;
  font-variant: normal;
}

@font-face {
  font-family: VarelaRoundRegular;
  src: url($font-path + "/VarelaRound-Regular.ttf") format('truetype');
  font-weight: normal;
  font-style: normal;
}
// @font-face {
//     font-family: 'claims on';
//     src: url($font-path +"/claims-on.eot");
//     src: url($font-path +"/claims-on.eot?#iefix") format('embedded-opentype'),
//          url($font-path +"/claims-on.woff") format('woff'),
//          url($font-path +"/claims-on.ttf") format('truetype'),
//          url($font-path +"/claims-on.svg#claims-on") format('svg');
//     font-weight: normal;
//     font-style: normal;
// }
// --------------------------------------------------
// To customize the look and feel of this app, you can override
// the Sass variables found in Ionic's source scss files.
// To view all the possible Ionic variables, see:
// http://ionicframework.com/docs/v2/theming/overriding-ionic-variables/
// Named Color Variables
// --------------------------------------------------
// Named colors makes it easy to reuse colors on various components.
// It's highly recommended to change the default colors
// to match your app's branding. Ionic uses a Sass map of
// colors so you can add, rename and remove colors as needed.
// The "primary" color is the only required color in the map.
/*$colors: (
  primary:    #488aff,
  secondary:  #32db64,
  danger:     #f53d3d,
  light:      #f4f4f4,
  dark:       #222
);*/
//$colors: ( primary: #0367ac, secondary: #6d6e71, danger: #656666, light: #a8e0fd, dark: #1d517a, green: #8AA43A, grey: #6C6E71, blue: #6db9d3, yellow: #e9ab14, red: #a7465d, white : #ffffff, textgrey: #5a5a61);

$darkTurq: #0367A5;
$lightTurq: #F0F8FA;

ion-button {
  text-transform: uppercase!important;
  border-radius: 0!important;
}
@mixin linksmall {
  font-family: Point32HealthSansMedium;
  font-size: 12px;
  line-height: 20px;
}
@mixin bodyP1420 {
  font-family: Point32HealthSansRegular;
  font-size: 14px;
  line-height: 20px;
}
@mixin bodyP1416 {
  font-family: Point32HealthSansRegular;
  font-size: 14px;
  line-height: 16px;
}
@mixin bodyP1215 {
  font-family: Point32HealthSansRegular;
  font-size: 12px;
  line-height: 15px;
}
@mixin bodyP1220 {
  font-family: Point32HealthSansRegular;
  font-size: 12px;
  line-height: 20px;
}
@mixin bodyP1820 {
  font-family: Point32HealthSansRegular;
  font-size: 18px;
  line-height: 20px;
}
@mixin bodyP1826 {
  font-family: Point32HealthSansLight;
  font-size: 18px;
  line-height: 26px;
}
@mixin h21620 {
  font-family: Point32HealthSansMedium;
  font-size: 16px;
  line-height: 20px;
}
@mixin h12026 {
  font-family: Point32HealthSansMedium;
  font-size: 20px;
  line-height: 26px;
}
@mixin itemList {
  text-transform: initial;
  font-family: Point32HealthSansMedium;
  font-size: 16px;
}
@mixin datavalueLeft {
  font-family: Point32HealthSansRegular;
  font-size: 14px;
  color: #191919;
  line-height: 20px;
}
@mixin datavalueRight {
  font-family: Point32HealthSansRegular;
  font-size: 14px;
  color: #191919;
  line-height: 20px;
  text-align: right;
}
@mixin dollars {
  font-family: Point32HealthSansBold;
  font-size: 14px;
  color: greytext;
  line-height: 20px;
}
@mixin datalabel {
  font-family: Point32HealthSansLight;
  font-size: 14px;
  color: greytext;
  line-height: 20px;
}
@mixin fieldlabel {
  font-family: Point32HealthSansRegular;
  font-size: 14px;
  color: #0367a5;
  line-height: 20px;
}
@mixin screentitle {
  font-family: Point32HealthSansMedium;
  font-size: 14px;
  line-height: 12px;
  color: #d6f4fc;
}
@mixin link {
  font-family: Point32HealthSansMedium;
  font-size: 14px;
  color: #0367A5;
  line-height: 20px;
}
@mixin smallPrimaryText {
  font-family: Point32HealthSansRegular;
  font-size: 12px;
  color: #0367a5;
  line-height: 20px;
}
@mixin headerLabel {
  font-family: Point32HealthSansMedium;
  color: #6D6F72;
  text-transform: uppercase;
  font-weight: 500;
  line-height: 60px;
  margin: 24px 0 16px 16px;
  background-color: #F5F5F5;
  height: 50px;
  padding-left: 20px;
  margin:0px;

}

@mixin subHeaderLabel {
  font-family: Point32HealthSansMedium;
  color: #6D6F72;
  text-transform: uppercase;
  font-weight: 500;
  background-color: #F5F5F5;
  padding: 10px;
  margin:0px;
}

@mixin subText {
  color: #5A5A61;
  font-family: Point32HealthSansRegular;
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
  text-align: left;
  margin-bottom: 0px;
  margin-top: 0px;
}

@mixin boldText {
  color: #5A5A61;
  font-family: Point32HealthSansBold;
  font-weight: 700;
}

@mixin initials {
  font-family: VarelaRoundRegular;
  font-size: 30px;
  font-weight: 700;
  background-color: #A0C8D3;
  border-radius: 100%;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  width: 75px;
  height: 75px;
  color: #FFF;
  line-height: 78px;
  text-align: center;
}
// App iOS Variables
// --------------------------------------------------
// iOS only Sass variables can go here
// App Material Design Variables
// --------------------------------------------------
// Material Design only Sass variables can go here
// App Windows Variables
// --------------------------------------------------
// Windows only Sass variables can go here
// App Theme
// --------------------------------------------------
// Ionic apps can have different themes applied, which can
// then be future customized. This import comes last
// so that the above variables are used and Ionic's
// default are overridden.
//@import "ionic.theme.default"; // Ionicons
// --------------------------------------------------
// The premium icon font for Ionic. For more info, please see:
// http://ionicframework.com/docs/v2/ionicons/
//@import "ionic.ionicons"; // Fonts
// --------------------------------------------------
//@import "roboto";
//@import "noto-sans";

$text-input-md-highlight-color-invalid: #e2e2e2;
$text-input-md-highlight-color-valid: #e2e2e2;
$text-input-md-show-invalid-highlight: #e2e2e2;
$text-input-md-highlight-color: #e2e2e2;
$text-input-md-show-valid-highlight: #e2e2e2;

$menu-width: 340px;

// Ionic 6 change belows

:root {
	
    /** Keep the Ionic default values same, don't replace them **/
  /** primary **/
  /** secondary **/
  /** tertiary **/
  /** success **/
  /** warning **/
  /** danger **/
  /** dark **/
  /** medium **/
  /** light **/

  /** These values are added for theming **/
  .THP {
    --ion-color-primary: #0367A5;
    --ion-color-primary-rgb: 3,103,165;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255,255,255;
    --ion-color-primary-shade: #035b91;
    --ion-color-primary-tint: #1c76ae;

    --ion-color-secondary: #5260ff;
    --ion-color-secondary-rgb: 82,96,255;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255,255,255;
    --ion-color-secondary-shade: #4854e0;
    --ion-color-secondary-tint: #6370ff;

    --ion-color-tertiary: #5260ff;
    --ion-color-tertiary-rgb: 82,96,255;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255,255,255;
    --ion-color-tertiary-shade: #4854e0;
    --ion-color-tertiary-tint: #6370ff;

    --ion-color-success: #2dd36f;
    --ion-color-success-rgb: 45,211,111;
    --ion-color-success-contrast: #000000;
    --ion-color-success-contrast-rgb: 0,0,0;
    --ion-color-success-shade: #28ba62;
    --ion-color-success-tint: #42d77d;

    --ion-color-warning: #ffc409;
    --ion-color-warning-rgb: 255,196,9;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0,0,0;
    --ion-color-warning-shade: #e0ac08;
    --ion-color-warning-tint: #ffca22;

    --ion-color-danger: #eb445a;
    --ion-color-danger-rgb: 235,68,90;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255,255,255;
    --ion-color-danger-shade: #cf3c4f;
    --ion-color-danger-tint: #ed576b;

    --ion-color-medium: #92949c;
    --ion-color-medium-rgb: 146,148,156;
    --ion-color-medium-contrast: #000000;
    --ion-color-medium-contrast-rgb: 0,0,0;
    --ion-color-medium-shade: #808289;
    --ion-color-medium-tint: #9d9fa6;

    --ion-color-light: #f4f5f8;
    --ion-color-light-rgb: 244,245,248;
    --ion-color-light-contrast: #000000;
    --ion-color-light-contrast-rgb: 0,0,0;
    --ion-color-light-shade: #d7d8da;
    --ion-color-light-tint: #f5f6f9;

    --ion-background-color: #ffffff;
	  --ion-background-color-rgb: 255,255,255;
	  --ion-background-color-contrast: #000000;
	  --ion-background-color-contrast-rgb: 0,0,0;
	  --ion-background-color-shade: #e0e0e0;
	  --ion-background-color-tint: #ffffff;

    --ion-tab-bar-background: #013E65;
    --ion-toolbar-background-contrast: #ffffff;
   

    --ion-text-color : #575656;
    --ion-text-color-rgb: 87, 86, 86;

    --ion-text-color : #575656;
    --ion-text-color-contrast-rgb: 87, 86, 86;

    --ion-color-button-next: #8AA43A;
	  --ion-color-button-next-rgb: 138,164,58;
	  --ion-color-button-next-contrast: #ffffff;
	  --ion-color-button-next-contrast-rgb: 0,0,0;
	  --ion-color-button-next-shade: #799033;
	  --ion-color-button-next-tint: #96ad4e;

    --ion-color-button-previous: #e9ab14;
	  --ion-color-button-previous-rgb: 233,171,20;
	  --ion-color-button-previous-contrast: #ffffff;
	  --ion-color-button-previous-contrast-rgb: 255,255,255;
	  --ion-color-button-previous-shade: #cd9612;
	  --ion-color-button-previous-tint: #ebb32c;

    /* Not working on toolbar
    --ion-toolbar-background : #0367A5;
    --ion-toolbar-background-rgb: 3,103,165;
    --ion-toolbar-background-contrast: #ffffff;
    --ion-toolbar-background-contrast-rgb: 255,255,255;
    --ion-toolbar-background-shade: #035b91;
    --ion-toolbar-background-tint: #1c76ae;
    */

    /* For dark mode
    --ion-background-color: #000000;
	  --ion-background-color-rgb: 0,0,0;
	  --ion-background-color-contrast: #ffffff;
	  --ion-background-color-contrast-rgb: 255,255,255;
	  --ion-background-color-shade: #000000;
	  --iion-background-color-tint: #1a1a1a;
    */
    

  }
  .USFHP {
    --ion-color-primary: #0367A5;
    --ion-color-primary-rgb: 3,103,165;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255,255,255;
    --ion-color-primary-shade: #035b91;
    --ion-color-primary-tint: #1c76ae;

    --ion-color-secondary: #5260ff;
    --ion-color-secondary-rgb: 82,96,255;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255,255,255;
    --ion-color-secondary-shade: #4854e0;
    --ion-color-secondary-tint: #6370ff;

    --ion-color-tertiary: #5260ff;
    --ion-color-tertiary-rgb: 82,96,255;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255,255,255;
    --ion-color-tertiary-shade: #4854e0;
    --ion-color-tertiary-tint: #6370ff;

    --ion-color-success: #2dd36f;
    --ion-color-success-rgb: 45,211,111;
    --ion-color-success-contrast: #000000;
    --ion-color-success-contrast-rgb: 0,0,0;
    --ion-color-success-shade: #28ba62;
    --ion-color-success-tint: #42d77d;

    --ion-color-warning: #ffc409;
    --ion-color-warning-rgb: 255,196,9;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0,0,0;
    --ion-color-warning-shade: #e0ac08;
    --ion-color-warning-tint: #ffca22;

    --ion-color-danger: #eb445a;
    --ion-color-danger-rgb: 235,68,90;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255,255,255;
    --ion-color-danger-shade: #cf3c4f;
    --ion-color-danger-tint: #ed576b;

    --ion-color-medium: #92949c;
    --ion-color-medium-rgb: 146,148,156;
    --ion-color-medium-contrast: #000000;
    --ion-color-medium-contrast-rgb: 0,0,0;
    --ion-color-medium-shade: #808289;
    --ion-color-medium-tint: #9d9fa6;

    --ion-color-light: #f4f5f8;
    --ion-color-light-rgb: 244,245,248;
    --ion-color-light-contrast: #000000;
    --ion-color-light-contrast-rgb: 0,0,0;
    --ion-color-light-shade: #d7d8da;
    --ion-color-light-tint: #f5f6f9;

    --ion-background-color: #ffffff;
	  --ion-background-color-rgb: 255,255,255;
	  --ion-background-color-contrast: #000000;
	  --ion-background-color-contrast-rgb: 0,0,0;
	  --ion-background-color-shade: #e0e0e0;
	  --ion-background-color-tint: #ffffff;

    --ion-tab-bar-background: #013E65;
    --ion-toolbar-background-contrast: #ffffff;
  

    --ion-text-color : #575656;
    --ion-text-color-rgb: 87, 86, 86;

    --ion-text-color : #575656;
    --ion-text-color-contrast-rgb: 87, 86, 86;
    

    --ion-color-button-next: #8AA43A;
	  --ion-color-button-next-rgb: 138,164,58;
	  --ion-color-button-next-contrast: #ffffff;
	  --ion-color-button-next-contrast-rgb: 255,255,255;
	  --ion-color-button-next-shade: #799033;
	  --ion-color-button-next-tint: #96ad4e;

    --ion-color-button-previous: #e9ab14;
	  --ion-color-button-previous-rgb: 233,171,20;
	  --ion-color-button-previous-contrast: #ffffff;
	  --ion-color-button-previous-contrast-rgb: 255,255,255;
	  --ion-color-button-previous-shade: #cd9612;
	  --ion-color-button-previous-tint: #ebb32c;


  }

  .ion-color-button-next {
    --ion-color-base: var(--ion-color-button-next);
    --ion-color-base-rgb: var(--ion-color-button-next-rgb);
    --ion-color-contrast: var(--ion-color-button-next-contrast);
    --ion-color-contrast-rgb: var(--ion-color-button-next-contrast-rgb);
    --ion-color-shade: var(--ion-color-button-next-shade);
    --ion-color-tint: var(--ion-color-button-next-tint);
  }

  .ion-color-button-previous {
    --ion-color-base: var(--ion-color-button-previous);
    --ion-color-base-rgb: var(--ion-color-button-previous-rgb);
    --ion-color-contrast: var(--ion-color-button-previous-contrast);
    --ion-color-contrast-rgb: var(--ion-color-button-previous-contrast-rgb);
    --ion-color-shade: var(--ion-color-button-previous-shade);
    --ion-color-tint: var(--ion-color-button-previous-tint);
  }

  .showAlertRedButton{
    color: red;
  }

  /*
  .dark-theme {
    --ion-color-primary: #000000;
    --ion-color-primary-rgb: 0, 0, 0;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255, 255, 255;
    --ion-color-primary-shade: #000000;
    --ion-color-primary-tint: #1a1a1a;
  }
  */

 

}



