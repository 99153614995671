//----------Freedom variables----------------

$background-color-orange: #f75718;
$background-color-green: #599c35;
$background-color-blue:#355864;

//----------Freedom App CSS------------------
@mixin thp {
    ion-nav{
        &.thp{
          a, #memberNameSelect .memberhdg, .segment-md-primary .segment-button,
          .linksmall {
            color: $background-color-green;
          }
          .HomeCardListAvator {
            background-color: $background-color-orange;
          }
          .input-has-focus, .input-has-value {
            .label-ios[floating]{
              color: $background-color-green;
            }
          }
          .toolbar-background-ios, .toolbar-background-md {
            background: $background-color-blue !important;
          }
          .brand-logo {
            width: 102px;
            height: 30px;
          }
          .tabs-ios, .tabs-md {
            .tabbar {
              background-color: $background-color-blue !important;
            }
            .tab-button[aria-selected=true], .tab-button[aria-selected=true] .tab-button-icon {
              background-color: $background-color-green;
            }
          }  
        }
      }
     
}
ion-select {  
  font-size: 14px;
}
