// http://ionicframework.com/docs/v2/theming/
// App Global Sass
// --------------------------------------------------
// Put style rules here that you want to apply globally. These
// styles are for the entire app and not just one component.
// Additionally, this file can be also used as an entry point
// to import other Sass files to be included in the output CSS.
//
// Shared Sass variables, which can be used to adjust Ionic's
// default Sass variables, belong in "theme/variables.scss".
//
// To declare rules for a specific mode, create a child rule
// for the .md, .ios, or .wp mode classes. The mode class is
// automatically applied to the <body> element in the app.
/*

body{font-size: 14px;}
.toolbar-background{background: #fff;}
.appFooter{background-color:#e6e6e6;
  padding: 1rem;
//  height: auto;
  font-size: 	1rem;
  color: #5A5A61;
  line-height: 15px;
  a{color:#5A5A61;padding-left: 2rem;}
}


.item-md,.item-ios{padding-right: 16px;}
.checkbox-md .item-md.item-block .item-inner,.checkbox-ios .item-ios.item-block .item-inner {border-bottom:0!important}
.item.item-md .checkbox-md{margin-right: 16px!important;}
.button-md{height:4.8125rem;}
.label{font-size:1.45rem!important;}

.brand-logo{width:40%;height:auto;}

.checkboxGridBg{background: rgba(109,185,211,0.10);padding-bottom: .875rem;
.item{background: none!important;
//.item-inner{border-bottom:0!important;}
}
}
//.segment-button{font-size:1.4rem!important;}
.app-sub-header{color:#fff;background-color: #0367ac;font-size:	1.4rem; text-transform: uppercase;} */
/*body{
  height: 100%!important;
  }
.toolbar-background{background: #fff;}*/
@import '../theme/thp.scss';
@import "../theme/variables.scss";  
// $app-type: 'application_type';
@include thp;
$app-type: 'thp';

  /*
  ion-app.ios {
    background-color: #181919!important;
  }
  */
  .cba_succdialog_modal-content, .cba_cfmSuccdialog_modal-content {
    margin: 15% !important;
  }
  
  h1 {
    font-size: 20px;
    color: #5a5a61;
    margin-top: 0 !important;
    margin-bottom: 1rem !important;
    font-family: Point32HealthSansBold;
    text-align: left;
  }
  
  .appFooter .col {
    background-color: #181919;
    padding: 3px;
    font-size: 1rem;
    color: #ABACAC;
    line-height: 15px;
  
    a {
      color: #ABACAC;
      padding-left: 2rem;
      text-decoration: underline;
      font-weight: normal;
    }
  }
  
  .item > ion-icon,
  .item-inner > ion-icon {
    font-size: 2rem!important;
  }
  
  .col {
    padding: 0;
  }
  
  .item-ios,
  .item-md {
    padding-right: 0;
    padding-left: 0;
  }
  
  .checkbox-ios .item-ios.item-block .item-inner,
  .checkbox-md .item-md.item-block .item-inner {
    border-bottom: 0!important;
    font-family: Point32HealthSansRegular;
  }
  
  .item.item-md .checkbox-md {
    margin-right: 16px !important;
  }
  
  .label {
    font-size: 1.45rem!important;
  }
  
  .brand-logo {
    width: 40%;
    height: auto;
    padding-top:50px;
    
  }
  
  .checkboxGridBg {
    font-family: Point32HealthSansRegular;
    background: rgba(109,185,211,0.10);
    padding: 8px;
    margin: 1rem 0;
    border: 2px solid #C0DAEB;
  
    .item {
      background: none!important;
    }
  
    .col span {
      text-align: left;
      float: left;
      margin: 0 0 0 1rem;
    }
  }
  
  ion-button {
    font-family: Point32HealthSansMedium;
  }
  
  .ion-button-ios-green,
  .ion-button-md-green {
    border-radius: 8px!important;
  }
  
  .darken {
    color: #1B1B1B;
  }
  
  .item-datetime .label-ios-primary,
  .item-datetime .label-md-primary,
  .item-input .label-ios-primary,
  .item-input .label-md-primary,
  .item-select .label-ios-primary,
  .item-select .label-md-primary,
  .label-ios-primary,
  .label-md-primary {
    color: #e2e2e2;
    font-size: 0.875em;
    font-family: Point32HealthSansRegular;
  }
  
  .app-sub-header {
    color: #fff;
    background-color: #0367ac;
    font-size: 1.4rem;
    text-transform: uppercase;
  }
  //Logo Style Section
  
  .logo {
    text-align: center;
    //padding-top: 2%;
    //background-color: white;
  }
  
  .pageBg {
    background: url("../assets/img/pagebg.jpg")no-repeat center center;
    height: 41vh;
    background-size: cover;
    display: none;
  }
  
  .pageBg p {
    padding: 0;
    margin: 0 auto;
  }
  
  .formWrapper {
    padding-left: 2em;
    padding-right: 2em;
    // min-height: 74vh;
    // display: block;
    // height: calc(100% - 74px);
    padding-top: 2rem;
  }
  
  .mainContainer {
    display: flex;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    flex-direction: column;
    -webkit-flex-direction: column;
    //min-height: 100vh;
    min-height: 100%;
  }
  
  .footer,
  .header,
  .mainContent {
    flex: 1;
    -webkit-flex: 1;
    // border: 1px solid red;
  }
  
  .swiper-container-horizontal > .swiper-pagination-bullets,
  .swiper-pagination-custom,
  .swiper-pagination-fraction {
    top: 0;
    left: 90px;
  }
  //03-May-2017
  
  .col,
  .list,
  p {
    font-size: 14px;
    font-family: Point32HealthSansRegular;
  }
  
  .eoBorder {
    border-bottom: 1px solid #999999;
    padding: 8px 0 0;
  }
  
  .eoBorderTop {
    border-top: 1px solid #999999;
    padding: 8px 0 0;
  }
  
  .eoBorderBottom {
    margin: 0 0 8px;
  }
  
  .eobborder .radio-md {
    top: 7px;
  }
  
  .toggle-ios .toggle-icon,
  .toggle-md .toggle-icon {
    background-color: #989696;
  }
  
  .toggle-ios .toggle-checked .toggle-inner,
  .toggle-ios.toggle-checked .toggle-inner,
  .toggle-md-secondary .toggle-checked .toggle-inner,
  .toggle-md.toggle-checked .toggle-inner {
    background-color: #8AA43A;
  }
  
  .toggle-ios-secondary .toggle-checked,
  .toggle-ios-secondary.toggle-checked .toggle-icon,
  .toggle-md-secondary .toggle-checked,
  .toggle-md-secondary.toggle-checked .toggle-icon {
    background-color: #C0CA94;
  }
  
  .toggle-ios .toggle-icon::before,
  .toggle-md .toggle-icon::before {
    background-color: a5a5a5;
  }
  
  .footerCustomerSupport {
    font-size: 12px;
    font-family: Point32HealthSansRegular;
    margin: 30px 0;
  }
  
  .item-datetime .label-ios,
  .item-datetime .label-md,
  .item-input .label-ios,
  .item-input .label-md,
  .item-select .label-ios,
  .item-select .label-md {
    color: #191919;
  }
  
  .item-input ion-input,
  .item-input ion-textarea {
    color: #616D7E;
  }
  
  .item-ios .radio-ios[item-left],
  .item-md .radio-md[item-left] {
    margin: 11px 6px 10px 4px;
  }
  
  h3 {
    font-family: Point32HealthSansLight;
    font-weight: normal;
    color: #4d4f4f;
    font-size: 18px;
  }
  
  h4 {
    font-family: Point32HealthSansMedium;
    color: #4d4f4f;
    font-size: 16px;
  }
  
  a {
    text-decoration: none;
    font-weight: none;
    color: #0367a5;
  }
  //04-May-2017
  
  .radio-ios .radio-checked .radio-inner,
  .radio-md .radio-checked {
    border-color: #8AA43A;
  }
  
  .radio-md .radio-inner {
    background-color: #8AA43A;
  }
  
  .mobileSmsHeading {
    margin: 4rem 0 0;
  }
  
  .securityQueBlock {
    text-align: left;
  
    p {
      margin: 9px 0 18px;
    }
  
    h4 {
      margin: 4rem 0 0;
    }
  }
  
  .rememberDeviceBlock {
    h4 {
      margin: 4rem 0 1.3rem;
    }
  }
  
  .regSubHeading {
    font-family: Point32HealthSansRegular;
    color: #5a5a61;
    margin: -4px 0 16px;
    float: left;
    width: 100%;
  }
  //05-May-2017
  
  .noPadding {
    padding: 0;
  }
  
  .alert-ios .alert-radio-label,
  .alert-md .alert-radio-label,
  .select-text {
    white-space: normal;
  }
  //09-May-2017
  
  .appFooter {
    border: none !important;
    box-shadow: none !important;
    outline: none !important;
    background-color: #181919;
    padding: 0.5rem 0; //  margin: 1.3rem 0 0 0;
    height: 74px;
    font-size: 10px;
    color: #ABACAC
    // position: absolute;
    // bottom: 0;
    // left: 0;
  }
  


  .appFooter a {
      color: #ABACAC;
      padding-left: 2rem;
      font-weight: normal;
  }
  // .item > ion-icon, .item-inner > ion-icon{min-height:1.8rem; margin: 26px 0 0 0 !important;}
  
  .flex-container {
    display: flex;
    width: 75%;
    height: 25px;
    flex-direction: row;
    margin-top: 10px;
  
    .text-input-ios,
    .text-input-md {
      width: 100%!important;
      font-family: Point32HealthSansRegular;
      font-size: 14px;
    }
  }
  .flex-item {
    width: 100%;
     height: auto;
     display: flex;
  }
  
  .text-input-ios,
  .text-input-md {
    margin: 0;
  }
  
  .flex-item:nth-of-type(1) {
    flex-grow: 1;
    width: 55px;
    margin-right: 5px;
  }
  
  .flex-item:nth-of-type(2) {
    flex-grow: 1;
    width: 10px;
    margin-right: 5px;
    margin-left: 2px;
  }
  
  .flex-item:nth-of-type(3) {
    flex-grow: 1;
    width: 55px;
    margin-right: 5px;
  }
  
  .flex-item:nth-of-type(4) {
    flex-grow: 1;
    width: 10px;
    margin-right: 5px;
  }
  
  .flex-item:nth-of-type(5) {
    flex-grow: 1;
    width: 87px;
  }
  
  .loginAlternateMethod {
    background-color: #FFFFFF;
    padding: 32px 0px;
  }
  
  .list-ios > .item-wrapper:last-child .item-block,
  .list-ios > .item-wrapper:last-child .item-block,
  .loginAlternateMethod .list-ios .item-block .item-inner,
  .loginAlternateMethod .list-ios > .item-block:first-child,
  .loginAlternateMethod .list-ios > .item-block:last-child,
  .loginAlternateMethod .list-md .item-block .item-inner,
  .loginAlternateMethod .list-md .item-block .item-inner,
  .loginAlternateMethod .list-md > .item-block:first-child,
  .loginAlternateMethod .list-md > .item-block:first-child,
  .successLoginAlternateMethod .list-ios .item-block .item-inner,
  .successLoginAlternateMethod .list-ios > .item-block:first-child,
  .successLoginAlternateMethod .list-ios > .item-block:last-child {
    border: none;
  }
  
  .item-inner > ion-icon,
  .loginAlternateMethod .item > ion-icon {
    margin: auto;
  }
  
  .component-box {
    border: 1px solid rgb(228, 228, 228);
  }
  
  .loginCredentials {
    margin: 3rem 0;
  
    p {
      text-align: justify;
    }
  }
  //10-May-2017
  
  .forgotPasswordButton {
    margin: 3rem 0;
  }
  
  ion-app.ios [padding-end],
  ion-app.ios [padding-end] .scroll-content,
  ion-app.md [padding-end],
  ion-app.md [padding-end] .scroll-content {
    text-align: right;
  }
  
  .successLoginAlternateMethod {
    margin: 2rem 0 0;
  }
  
  .loginSecurityContent {
    p {
      margin: 3rem 0 0;
      text-align: left;
    }
  
    p.loginSecurityFirst {
      margin: 1rem 0 0;
    }
  }
  
  .label-ios[stacked],
  .label-md[stacked] {
    text-align: left;
  }
  //11-May-2017
  
  .input-has-focus .label-ios[floating],
  .input-has-value .label-ios[floating] {
    color: #0367ac;
  }
  
  .resetPasswordTxt {
    text-align: left;
    margin: 2rem 0 0;
  }
  
  .resetPasswordTxt1 {
    text-align: left;
    margin: 1rem 0;
  }
  //12-May-2017
  
  .alert-wrapper {
    min-width: 360px;
    max-height: 100%;
  }
  //15-May-2017
  
  .checkbox-ios .checkbox-checked .checkbox-inner,
  .checkbox-md .checkbox-checked .checkbox-inner {
    height: 14px;
    width: 8px;
    left: 6px;
  }
  
  .checkbox-ios .checkbox-icon,
  .checkbox-md .checkbox-icon {
    width: 23px;
    height: 23px;
  }
  
  .checkbox-ios .checkbox-checked,
  .checkbox-md .checkbox-checked {
    border-color: #8AA43A;
    background-color: #8AA43A;
  }
  //22-May-2017
  
  .pageTitle {
    h1 {
      float: left;
      width: 50%;
    }
  
    h3 {
      float: left;
      width: 100%;
      text-align: left;
    }
  }
  
  .regBullets {
    float: right;
    margin: 7px 0 0;
  
    ul {
      margin: 0 auto;
  
      li {
        list-style: none;
        float: left;
        font-size: 10px;
        border: 2px solid #d1d1d1;
        border-radius: 100%;
        margin: 0 2px;
        color: #fff;
        width: 11px;
        height: 11px;
        font-family: Point32HealthSansRegular;
      }
  
      li.active {
        color: #6db9d3;
        background: #6db9d3;
        border: 0 solid #d1d1d1;
      }
    }
  }
  
  .appFooter {
    height: 74px;
  }
  
  .errorMsg {
    text-align: left;
    color: #e90000;
    padding: 0;
    margin: 3px 0 0;
  }
  
  .item-ios.item-label-floating [item-right],
  .item-ios.item-label-stacked [item-right],
  .item-md.item-label-floating [item-right],
  .item-md.item-label-stacked [item-right] {
    margin-top: 3.5rem;
    margin-bottom: 0;
  }
  
  .popupContainer {
    display: flex;
    flex-direction: column;
  }
  
  .popupHeader {
    flex: 1;
    border: 1px solid red;
  }
  
  .popupBody {
    flex: 1;
    border: 1px solid blue;
  }
  
  .popupFooter {
    flex: 1;
    border: 1px solid green;
  }
  
  .input-cover {
    position: static;
  }
  
  .scroll-content {
    padding-bottom: 0 !important;
    -webkit-overflow-scrolling: auto !important;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin-bottom: -1px;
    width: auto;
    height: auto;
  }
  
  .HomeMainContainer {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
  
  .toolbar-ios,
  .toolbar-md {
    min-height: 50px!important;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.80);
    text-align: center;
  }
  // .toolbar-title-md {
  //   font-size: 1.7rem;
  //   font-weight: 600;
  //   color: #000;
  //   pointer-events: auto;
  //   padding: 0 !important;
  //   margin-left: 8%;
  // }
  
  ion-title {
    position: absolute;
    top: 0;
    left: 0;
    padding: 0 80px 1px;
    width: 100%;
    height: 100%;
    text-align: center;
  }
  
  .back-button.show-back-button {
    z-index: 9999;
    display: inline-block;
  }
  
  .HomeMainContent,
  .HomePageHeader {
    flex: 1;
  }
  // .HomePageHeader{border:2px solid red;}
  // .HomeMainContainer{border:2px solid #e42f43}
  // .HomeMainContent{border:2px solid blue;}
  
  .HomeMainContent {
    background: url('../assets/img/runner3.png') top center no-repeat;
    background-size: cover;
  }
  
  .HomeMainContent h1,
  h2 {
    text-align: center;
  }
  
  .HomeMainContent .item-ios [item-left] {
    margin: 0 !important;
  }
  
  .HomeCardContainer {
    display: flex;
    flex-direction: column;
    padding: 1em;
    position: relative;
  }
  
  .HomeCardList {
    flex: 1;
    margin-bottom: 0.5em;
    height: 20%;
    width: 100%;
    padding-right: 0;
    --padding-start :0px;
    --background: rgba(255,255,255,0.62);
    --border-radius :10px;
  }
  
  #providerSearchLink, #pharmacyLink {
    text-decoration: none;
    font-weight: normal;
  }
  
  .HomeCardListContentBlock {
    min-height: 65px;
    z-index: 1;
    display: flex;
    -webkit-flex-direction: row;
    flex-direction: row;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center;
    border-radius: 10px;
    width: 100%;
  }
  
  .HomeCardListAvator {
    flex: 1;
    background-color: rgba(167,70,93,1);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    min-height: 70px;
    position: absolute;
    width: 18%;
    z-index: 3;
    left: 0;
  
    img {
      max-width: 50%!important;
    }
  }
  
  .HomeCardListBody {
    color: #000;
    font-family: MontserratMedium;
    font-size: 18px;
    text-transform: uppercase;
    //padding-left: 300px;
    //margin-left: 500px;
    //position: relative;
    text-align: left;
    width:70%;
    word-wrap: normal;
    
  }
  
  .HomeUserDetailsWelcome h1 {
    font-family: Point32HealthSansRegular;
    font-size: 24px;
    margin-top: 1em !important;
    margin-bottom: 1em !important;
    color: #fff;
  }
  
  .HomeUserMembersId h2 {
    margin-bottom: 0 !important;
  }
  
  .HomeUserMemberSubText h2 {
    margin-top: 5px !important;
  }
  
  .HomeUserMemberSubText h2,
  .HomeUserMembersId h2 {
    font-family: Point32HealthSansRegular;
    font-size: 18px;
    color: #000;
  }
  // .modal-wrapper {
  //     position: absolute;
  //     width: 90%;
  //     height: 35%;
  //     top: 5%;
  //     left: 5%;
  //   }
  //
  //   @media not all and (min-height: 600px) and (min-width: 768px) {
  //   ion-modal ion-backdrop {
  //     visibility: visible;
  //   }
  // }
  
  .no-scroll {
    overflow: hidden;
  }
  
  #ModalWrapper {
    display: flex;
    flex-direction: column;
  }
  
  #ModalHeader {
    flex: 1;
    border: 1px solid red;
  }
  
  #ModalContent {
    flex: 1;
    border: 1px solid blue;
    background-color: #fff;
  }
  
  #ModalFooter {
    flex: 1;
    border: 1px solid green;
  }
  
  .text-input {
    color: #191919!important;
  }
  
  .item-ios.item-block .item-inner,
  .item-md.item-block .item-inner {
    border-bottom: 0.55px solid #808080;
  }
  
  .alert-ios .alert-checkbox-group,
  .alert-ios .alert-radio-group,
  .alert-md .alert-checkbox-group,
  .alert-md .alert-radio-group {
    max-height: 375px;
  }
  //Popup Button Style
  
  .footerFlexContainer {
    display: flex;
    margin: 1rem 0 0;
    width: 100%;
  
    .footerButtonItems {
      flex: 1;
      text-align: right;
    }
  
    .footerButtonItemsCancel {
      flex: 1;
      text-align: right;
    }
  }
  
  .popupFlexContainer {
    display: flex;
    width: 100%;
    // height: 36vh;
    height: 94%;
    flex-direction: column;
    // justify-content: center;
    // align-items: center;
    h1 {
      margin: 0 auto !important;
    }
  
    .popupFlexHeader {
      flex: 1;
    }
  
    .popupFlexContent {
      flex: 2;
    }
  
    .popupFlexFooter {
      flex: 1;
    }
  }
  
  .setPinPopupFlexHeader {
    width: 100%;
    text-align: left;
  }
  // ion-modal {
  //   background-color: rgba(0, 0, 0, .5) !important;
  //   transition: opacity 0.25s ease-in-out;
  //   padding: 29vh 10vw;
  //   //border:5px solid red;
  // }
  // ion-modal {
  //   // transparent black background overlay
  //   background-color: rgba(0, 0, 0, .5) !important;
  //   transition: opacity 0.25s ease-in-out;
  //   // padding: 29vh 10vw;
  // }
  // .modal-wrapper {
  //   // transparent black background overlay
  //   background-color: rgba(0, 0, 0, .5) !important;
  //   padding: 24vh 10vw;
  //   border:4px solid red;
  //   min-height: 50%;
  //   min-width: 50%
  // }
  
  .content-ios,
  .content-md {
    color: #5a5a61!important;
  }
  
  .securityQueBlock {
    .label-md[floating] {
      color: #0367ac;
    }
  
    #nonFloating {
      color: #191919;
    }
  
    .input-has-focus > .item-inner > .input-wrapper > #nonFloating[floating] {
      color: #0367ac;
    }
  
    .input-has-value > .item-inner > .input-wrapper > #nonFloating[floating] {
      color: #0367ac;
    }
  }
  
  .closeButton {
    float: right;
    width: 100%;
  
    ion-icon {
      float: right;
    }
  }
  
  .popupFlexContainer {
    padding: 1rem;
  }
  
  .HomeCardList {
    cursor: pointer;
  }
  
  ion-alert.alert-top {
    align-items: center;
  }
  ///////////////********** InnerPages CSS **************/////////////////
  
  #memberHeader {
    .toolbar-ios,
    .toolbar-md {
      box-shadow: 0 2px 4px 0 rgba(0,0,0,0.25);
    }
  
    .toolbar-background-ios,
    .toolbar-background-md {
      background: $darkTurq;
    }
  
    .toolbar-title-ios,
    .toolbar-title-md {
      color: #d6f4fc;
      text-transform: uppercase;
      font-family: Point32HealthSansMedium;
      font-size: 14px;
      font-weight: 100!important;
      white-space: unset;
    }
  
    .toolbar-title-ios {
      padding-bottom: 0px;
    }
  
    span.back-button-text {
      text-decoration: none!important;
      @include screentitle;
      color: #fff;
      text-transform: none;
    }
    // .back-button-text::first-letter{text-transform: capitalize;}
    .back-button-icon-ios,
    .back-button-icon-md {
      // color: #6db9d3;
      color: #fff;
    }
  }
  
  #memberNameSelect {
    background-color: $lightTurq;
    // height: 60px;
    padding: 0 1em;
    display: flex;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
    // margin-bottom: 1rem;
    width: 100%;
    height: auto;
  
    .item-inner {
      border: 0!important;
    }
  
    #memberSelectBtn {
      width: 100%;
      text-align: left;
      font-weight: normal;
    }
  
    ion-button {
      background: none!important;
      padding-left: 0;
      padding-right: 0;
      text-align: left;
      margin-top: 0.5rem;
    }
  
    ion-label {
      margin-right: 0;
      border-bottom: 0;
    }
  
    ion-icon {
      margin-top: 0.5rem;
    }
  
    .item-ios.item-block .item-inner,
    .item-md.item-block .item-inner {
      padding-right: 0;
    }
  
    ion-label p {
      white-space: normal;
      font-weight: bold;
    }
  
  
    .div1 {
      flex: 1;
    }
  
    .div2 {
      flex: 3;
      font-size: 12px;
      max-width: 90%;
    }
  
    .item-ios,
    .item-md {
      background: none;
    }
  }
  
  #memberSelectDate {
    min-height: 46px;
    font-family: Point32HealthSansRegular;
    font-size: 12px!important;
    flex-direction: row-reverse;
  
    .div1 {
      flex: 2;
    }
  
    .div2 {
      flex: 1;
      font-family: Point32HealthSansMedium;
      font-size: 12px!important;
      font-weight: 100;
      color: $darkTurq;
      margin-right: 8px;
      text-align: right;
    }
  }
  
  #memberBenefitSegment {
    ion-segment {
      padding-top: 5px;
      background: $lightTurq;
      border-top-width: 0!important;
    }
  
    .segment-button {
      background: none;
      text-transform: uppercase;
      font-family: Point32HealthSansMedium;
      font-size: 14px;
      height: 4.7rem;
    }
  
    .segment-ios .segment-button,
    .segment-md .segment-button {
      border-bottom-width: 0!important;
    }
  
    .segment-activated {
      box-shadow: 0 -2px 15px -9px grey;
      color: #5a5a61;
      background: #fff;
    }
  }
  
  #memberNameDateBlock {
    margin-bottom: 0 !important;
  }
  
  .back-button.show-back-button {
    color: #fff!important;
  }
  
  #benefitsListItems ion-label, #mmaListItems ion-label{
    white-space: normal!important;
  }
  
  #benefitsListItems p {
    @include itemList;
    color: #5a5a61;
  }
  
  .item-ios[detail-push] .item-inner,
  a.item-ios:not([detail-none]) .item-inner,
  ion-button.item-ios:not([detail-none]) .item-inner {
    background-image: url("../assets/img/rightarrow.svg");
  }
  
  ion-list:first-child {
    border-top: 1px solid #d1d1d1;
  }
  
  .item-ios.item-block .item-inner,
  .item-md.item-block .item-inner {
    border-bottom: 0.55px solid #d1d1d1;
  }
  ////////////////////// Tabs CSS /////////////////////////////////////
  
  .tabs-ios .tab-button-text,
  .tabs-md .tab-button-text {
    text-transform: uppercase;
    font-family: Point32HealthSansMedium;
    font-weight: 100;
    font-size: 9px;
  }
  
  .tabs-ios .tabbar,
  .tabs-md .tabbar {
    background-color: #013E65;
    //height: 60px;
    border-top: 0;
  }
  // .tabs-md .tab-button,.tabs-ios .tab-button{min-height: 7rem;}
  
  .tabs-ios .tab-button,
  .tabs-ios .tab-button-icon,
  .tabs-md .tab-button,
  .tabs-md .tab-button-icon {
    color: #6DB9D3;
  }
  
  .tabs-ios .tab-button,
  .tabs-md .tab-button {
    min-height: 5.6rem;
  }
  
  .tabs-ios .tab-button[aria-selected=true],
  .tabs-ios .tab-button[aria-selected=true] .tab-button-icon,
  .tabs-md .tab-button[aria-selected=true],
  .tabs-md .tab-button[aria-selected=true] .tab-button-icon {
    color: #D5F4FC;
    background-color: #0367A5;
  }
  // [class*='icon-']:before{
  // 	display: inline-block;
  //    font-family: 'claims on';
  //    font-style: normal;
  //    font-weight: normal;
  //    line-height: 1;
  //    -webkit-font-smoothing: antialiased;
  //    -moz-osx-font-smoothing: grayscale
  // }
  // .icon-myicon1:before{content:'\0041';}
  // //.icon-myicon1:before{content:'\0042';}
  
  .viewMoreLink,
  .viewMoreLinkUp {
    float: right;
  }
  
  .viewMoreLink::after {
    content: url("../assets/img/downarrow.svg");
    margin-left: 4px;
  }
  
  .viewMoreLinkUp::after {
    content: url("../assets/img/uparrow.svg");
    margin-left: 4px;
    width: 12px;
    height: 12px;
  }
  
  ////////////////////// Card UI //////////////
  
  .cardWrapper {
    display: flex;
    flex-direction: column;
    background: #FFFFFF;
    border: 1px solid #D1D1D1;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.25);
    border-radius: 8px;
    padding: .5rem;
    margin-bottom: .5rem;
    font-family: Point32HealthSansRegular;
  
    .row1,.row2,.row3,.row4{flex:1;display:flex;flex-direction: row; padding: 1px 0;}
  
    // Normal card left side text
    .col1 {
      text-align: left;
      flex-wrap: nowrap;
      flex:1;
      @include bodyP1420;
    }
    
    // Large provider name
    .row1 .col1{
      font-family: Point32HealthSansRegular;
      font-size: 18px;
      color: #000;
      line-height: 20px;
      text-align: left;
      font-weight: 300;
    }
  
    // For referrals dates
    .col1-no-flex {
      text-align: left;
      flex-wrap: nowrap;
      color: #5A5A61;
      font-family: Point32HealthSansRegular;
      line-height: 20px;
    }
  
    // TMP visits allowed label
    .small-label {
      text-align: right;
      @include bodyP1220;
    }
  
    .med-label {
      text-align: right;
      @include bodyP1420; 
      @include boldText; //Bold loaded over book
    }
  
    .large-label {
      text-align: right;
      @include bodyP1820;
      @include boldText;
    }
  
    // old claims thing
    // .row1 .col2{flex-grow: 1;
    //   .yellowalerticon{text-align: right;}
    // }
  
  }
  
  //////////////////////  Referrals //////////////
  
  .status-dot {
    height: 11px;
    width: 11px;
    border-radius: 50%;
    margin-right: 3px;
    display: inline-block;
    background-color: #D50000;
  }
  
  .active, .authorized, .approved {
    background-color: #7FB93E;
  }
  
  .not.approved, .not.authorized, .not.active {
    background-color: #D50000;
  }
  
  .pending, .in.process, .partially.approved {
    background-color: #E9AB14;
  }
  
  ////////////////////// Member Benefits Category & Sub Category Page //////////////////

  .benefitLandigMsg {
    display: flex;
    flex-direction: column;
    background: $lightTurq;
    border: 1px solid #D1D1D1;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.25);
    //border-radius: 8px;
    padding: .5rem;
    margin-bottom: .5rem;
    font-family: Point32HealthSansRegular;
    
    
  }
  
  #memberBenefitsCategoryPage {
    p {
      @include bodyP1420;
    }
  
    p.p1 {
      @include bodyP1416;
      margin-top: 1rem;
    }
  
    p.p2 {
      @include bodyP1215;
    }
  
    p.memberTitle {
      @include fieldlabel;
    }
  
    p.memberTitleSecondary {
      color: #5a5a61;
    }
  
    p.memberName {
      font-size: 1.4rem;
      font-family: Point32HealthSansRegular;
      color: textgrey;
    }
  
    p.asofDate {
      font-family: Point32HealthSansRegular;
      font-size: 12px;
      color: textgrey;
      line-height: 15px;
      margin-top: 0 !important;
      margin-bottom: 0 !important;
    }
  
    #benefitsListItems p {
      @include itemList
    }
  
    h1.benefitsCategoryHeading {
      font-family: Point32HealthSansMedium;
      font-size: 20px;
      color: textgrey;
      line-height: 26px;
    }
  
    a.linksmall {
      @include linksmall;
    }
  
    #memberHeader {
      button#topBackbtn {
        font-size: 12px;
        color: #d6f4fc!important;
  
        span.button-inner {
          justify-content: flex-start!important;
        }
      }
      // button#topBackbtn::first-letter{
      //   text-transform: capitalize;
      // }
    }
    ////////////////// Tier Prescription ///////////////////
    #benefitsTableBlock {
      h2 {
        @include h21620;
        background: #f0f8fa;
        padding: 4px;
        text-align: left;
        color: textgrey;
  
        span {
          @include bodyP1215;
          margin-left: 6px;
        }
      }
  
      .datavalueLeft {
        @include datavalueLeft;
      }
  
      .dollars {
        @include dollars
      }
  
      .benefitsTableTitle {
        margin-top: 0.5rem;
      }
  
      ion-row.benefitsTableData {
        padding-left: 12px;
      }
  
      ion-row.benefitCopayComments {
        padding-left: 12px;
        margin-bottom: 1rem;
      }
  
      .memberDollarBalance {
        width: 50%;
        margin: 0 auto;
      }
    }
  }
  // .benefitsTableBlock{border:1px solid red;}
  // .memberDollarBalance{width:50%;margin:0 auto;}
  // .memberIndividualsBalance,.memberFamiliesBalance{display:flex;border:1px solid green;}
  // .memberIndividualsBalance .one,.memberFamiliesBalance .one,
  // .memberIndividualsBalance .two,.memberFamiliesBalance .two
  // {flex: 1;border:1px solid blue;}
  // .memberIndividualsBalance .two,.memberFamiliesBalance .two{}
  
  .alert-md .alert-message {
    text-align: center;
  }
  /* PLAN LIMITS PAGE CSS */
  // #planLimitsPage {
  //   background-color: $lightTurq;
  
  //   #planLimitsPageHeader {
  //     text-align: center;
  
  //     .memberId,
  //     .name {
  //       @include bodyP1215;
  //       color: #191919;
  //     }
  
  //     .planName h2 {
  //       font-family: Point32HealthSansRegular;
  //       font-size: 18px;
  //       line-height: 20px;
  //       color: #191919;
  //       margin-top: 1rem;
  //     }
  
  //     .asofDate {
  //       @include bodyP1215;
  //       color: #5a5a61;
  //     }
  //   }
  
  //   #planLimitsContentBlock {
  //     h1 {
  //       @include h12026;
  //       color: white;
  //       background: #428dbc;
  //       background: #428dbc;
  //       padding: 5px 10px;
  //       margin-bottom: 0 !important;
  //     }
  
  //     .planLimitsBodyContent {
  //       background-color: white;
  //       margin-bottom: 0.2rem;
  //       padding-bottom: 0.5rem;
  //     }
  
  //     h2 {
  //       @include h21620;
  //       padding-left: 1rem;
  //       margin-top: 0.5rem;
  //     }
  
  //     .level1Heading {
  //       @include datavalueLeft;
  //       margin-left: 3rem;
  //     }
  
  //     .level2Heading {
  //       @include datavalueLeft;
  //       margin-left: 7rem;
  //       margin-top: 0.5rem;
  //     }
  //   }
  // }
  
  .above-chart {
    margin: 0 auto 10px auto;
    width: 100%;
    display: inline-block;
  }
  
  .bar-chart {
      background: #fff;
      border-radius: 30px;
      height: 25px;
      overflow: hidden;
      width: 100%;
      background-color: #D8D8D8;//-webkit-linear-gradient(#FFF, #EEE);
      border: 1px solid #979797;
      box-shadow: 0px 1px 0px 0px #000000;
    }
    
    .bar-chart .fillText {
      display: block;
      width: 90%;
      height: 25px;
      border-right-width: 0px;
      border-right-style: solid;
      border-right-color: rgb(89, 89, 89);
      color: rgb(0, 0, 0);
      padding: 8px 0px;
      text-align: center;
      white-space: nowrap;
      font-size: 12px;
      font-weight: bold;
      overflow: visible !important;
      margin-left: 10px;
    }
    
    .bar-chart .fill {
      background: #8AA43A;
      background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0, #8AA43A), color-stop(1, #8AA43A))
      !important;
      background-image: -o-linear-gradient(top, #8AA43A 0%, #8AA43A 100%) !important;
      background-image: -moz-linear-gradient(top, #8AA43A 0%, #8AA43A 100%) !important;
      background-image: -webkit-linear-gradient(top, #8AA43A 0%, #8AA43A 100%) !important;
      background-image: -ms-linear-gradient(top, #8AA43A 0%, #8AA43A 100%) !important;
      background-image: linear-gradient(to bottom, #8AA43A 50%, #8AA43A 100%) !important;
      border-radius: 3px;
      display: block;
      height: 25px;
      overflow: visible !important;
    }
  
    .rightText{
      float: right;
    }
  
    .padBottom {
        padding-bottom: 20px;
     }
  
    .it-text {
      padding-left: 10px;
      font-weight: 400;
      font-style: italic;
    }
  
    .tiered-text {
      position: absolute;
      font-size: 10pt;
    }
  
    .tiered-text-container {
      padding-top: 10px;
      position: absolute;
      white-space: nowrap;
      width: 83%;
    }
  
    .tiered-div {
      border-right: 2px dotted #4a4a4a;
      display: inline-block;
      position: absolute;
      height: 28px;
      z-index: 1;
    }
    
    .helpText {
      padding-top: 20px;
      padding-bottom: 20px;
      color: blue!important;
    }
  
  // About Page CSS
  
  .p1 {
    @include bodyP1416;
  }
  
  .AboutBodyContent {
    p {
      @include bodyP1215;
    }
  }
  
  .more-popover .popover-content {
    bottom: 50px;
    right: 0!important;
    left: auto!important;
    top: auto;
    --width: auto!important;
    border-radius: none!important;
  }
  
  
  
 
  
  
  ion-popover::part(content) {
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    width: 85% !important;
    --height:400px%;
    --max-height:600px%;
    
  } 
  
  
  
  .auto_width_popover .popover-content {
    --width: auto!important;
  }
  
  .min_width_popover .popover-content {
    --width: 60%!important;
  }
  
  .popupBtnStyle {
    padding: 1rem;
  }
  
  ion-app.md ion-popover.global-popover {
    top: 5rem;
  }
  
  .global-popover .popover-content {
    //@include border-radius($popover-ios-border-radius);
    --width: 82%;
    --min-width: 0;
    --min-height: 0;
    --max-height: 90%;
    left: 50px;
  }
  
  .global-popover .popover-arrow {
    position: absolute;
    display: block;
    overflow: hidden;
    --width: 20px;
    --height: 10px;
    top: 0;
    right: 2.5rem;
  }
  
  .global-popover .popover-arrow::after {
    //@include position(3px, null, null, 3px);
    //@include border-radius(3px);
    position: absolute;
    //z-index: $z-index-overlay-wrapper;
    --width: 14px;
    --height: 14px;
    background-color: #fff;
    content: "";
    transform: rotate(45deg);
  }
  
  .checkEmailPopover .popover-content {
    width: 82%;
    --min-width: 0;
    --min-height: 0;
    --max-height: 90%;
    left: 50px;
  }
  
  .centered-popover .popover-content {
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
  }
  
  .cloned-input {
    display: none !important;
  }
  /*
  $searchbar-background-color: #fff;
  $searchbar-toolbar-input-background: #D8D8D8;
  
  .searchbar {
    background: #fff;
    margin-bottom: 18px;
    border-bottom-width: 2px;
   }
  ion-searchbar{        
    .searchbar-input-container{            
        .searchbar-input{
            border-radius: 18px;
            background-color: #D8D8D8;
            text-align: center;
        }
    }
    &.searchbar-has-focus {
      .searchbar-input-container{            
        .searchbar-input{
          text-align: left;
          padding-left: 30px !important;
        }
        .searchbar-search-icon {
          left: 5%;
        }
      }
    }
  }
  */
  
  .pdfQuarters {
    border: 1pt solid #999999;
    font-size: 12px;
    text-align: center;
    padding: 10pt 5pt 5pt 10pt;
    margin: 10pt 5pt 5pt 10pt;
    float: left;
    display: block;
    position: relative;
    white-space: normal;
    border-radius: 5pt;
    box-shadow: 1pt 1pt 8pt 0pt grey;
    background-color: #FFFFFF;
  }
  
  .pdfIcon {
    content: url("../assets/img/pdfIcon.png");   
    width: 40%;
    height: auto;  
    margin-top: -6pt;
  }

  .pdfLinkIcon {
    content: url("../assets/img/pdfIcon.png");   
    padding-right: 6px;
    vertical-align: middle;
    float: left;
    

  }
  
  //more menu
  //.item ion-icon{margin-left: 1.5rem!important;margin-top: 7px!important;}
  //.item .item-inner{margin-left: 0!important;}
  
  .initials {
    @include initials;
    float: left;
    margin-right: 16px;
    margin-bottom: 16px;
  }
  
  //Search
  .searchContent {
    color: #5A5A61;
    @include bodyP1416;
    text-align: center;
  }
  
  .hidden {
    display: none;
  }
  
  .thp-toast {
     .toast-wrapper {
        transform: translateY(0px) !important;
        left: 0;
        right: 0;
        border-radius: 0;	
        background: #0367A5;
        text-align: center;
      }
  }
  
  button.mmaItem{
    .item-inner{
      border-bottom: none !important;
    }
  }
  
  //REL
  
  .forSurveyTitle {
    padding: 35px 20px 0 20px;
    word-break: break-word;
  
  }
  .forSurveyMessage{
    padding: 20px 35px 0 20px;
    text-align: left;
    margin-bottom: 10%;
  }
  
  .forSurveyMessageFont{
    font-size: 14px;
  }
   
  .forPageTitleh1{
    float: left;
    width: 60%;
    word-break: break-word;
    text-align: left;
  }
  
  
  //ForSurvey
  
  .forValue {
    color: #5A5A61;
    font-family: Point32HealthSansRegular;
    font-size: 14px;
    font-weight: 300;
    line-height: 17px;
    width: 290px;
    text-align: left;
  }
  .forListAll {
    border: 1px solid #6C6E71;
    background-color: #FFFFFF;
    border-radius: 2px;
    box-shadow: none;
    width: 18px;
    height: 18px;
  }
  
  .forTable {
    background-color: #FFFFFF;
    border: 1px solid #E3E3E3;
    box-shadow: none;
    width: 343px;
  }
  .margin-left-box{
      margin-left: 10px;
  }
  .paddingset-box{
    padding : 10px;
  }
  
  .forSelection{
    z-index: 1;
    background-color: #ffffff;
    width: 100%;
    height: 15%;
  }
  
  .forSelectionFixed{
    
    position: fixed;
    bottom: 0;
    z-index: 1;
    background-color: #ffffff;
    width: 100%;
    height: 15%;
  }
  
  .forIonContent {
    height: auto;
  }
  
  .forButtons {
    float: right;
    margin: 21px 12px;
  }
  
  .forSpanHead {
    font-weight: bold;
    font-size: 14px;
  }
  
  .forSpan{
    margin-bottom: 5%;
    text-align: left;
    font-size: 16px;
  }
  
  .forSpanHeadNoBreak {
    font-size: 14px;
    font-weight: bold;
    
  }
  
  .forSpanNoBreak{
    margin-bottom: 5%;
    text-align: left;
    font-size: 16px;
  }
  
  .forPrefHead{
    color: #6DB9D3;
  }
  
  .forScroll{
    overflow: scroll;
    height: 90%;
    padding:20px;
  }
  
  .forBold{
    font-weight: bold;
  }
  
  .ssoNotify {
    .alert-title {
      text-align: left;
      font-size: 20px;
    }
    .alert-message {
      text-align: left;
      font-size: 14px;
    }
    button.alert-button.btn-ok {
      background-color: #8AA43A;
      color: #fff;
      padding: 0 20px;
      font-size: 14px;
    }
  }
  
  .alert-button-text {
    text-transform: none !important;
    font-family: Point32HealthSansRegular;
    font-weight: 300;
  }
  
  .point32healthsans-text {
    font-family: Point32HealthSansRegular;
  }
  
  page-summary-of-payment-faq {
    ul li {
      padding-bottom: 10px;
    }
  }

  .memberHeading {
    background-color: $lightTurq;
    box-shadow: 0 0 3px 0 var(--ion-color-medium);
  }
  
  /*
    Ionic 6 changes
  */
  ion-popover ion-select-popover ion-radio-group ion-item ion-label{
    white-space: pre-line !important;
  }

  ion-select::part(text) {
    white-space: normal !important;
    transform: none !important;
  }
  
  .ion-button-next{
    --background: #8AA43A;
    //--color: #000000;
    --color: #ffffff;
  }

  .ion-button-previous {
    --background: #e9ab14;
    --color: #ffffff;
  }
  
  .more-pop-icon-button{
    --border-width:0px;
    --border-radius:0px;
    --background-hover:none;
  }
  

  ion-tab-button.tab-selected {
    background-color: var(--ion-color-primary);
    ion-label {
      color: #ffffff;
    }
    ion-icon{
      color: #ffffff;
    }
  }
  
  ion-header {
   //background-color: #0367ac;
   background-color: var(--ion-color-primary);
  }
  
  ion-toolbar .thplogo{
    //content: url("http://placekitten.com/301/301");
    content: url("../assets/img/thp_logo.png");
    background-color: var(--ion-color-primary);
    margin: 2% auto;
    position: relative;
    text-align: center;
    display: block;
    max-width: 25%;

  }

  ion-toolbar .usfhplogo{
    //content: url("http://placekitten.com/301/301");
    content: url("../assets/icons/usfhp-logo.svg");
    background-color: var(--ion-color-primary);
    margin: 0% auto 0%;
    position: relative;
    text-align: center;
    display: block;

  }
  /*
  .alert {
    --width: auto;
    --height: auto;
    --min-width: 50%;
    --max-width: 75%;
    --min-height: auto;
    --max-height: 45%%;
    --overflow: auto;
  }
  */

  .popalert .alert-wrapper {
    --width: auto;
    --height: auto;
    --min-width: 50%;
    --max-width: 75%;
    --min-height: auto;
    --max-height: 45%%;
    --overflow: auto;
    
  }


  //----------Load styles based on app type (application_type)--------

  
  
  